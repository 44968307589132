.testimonial-section {
  @include max-screen(992, 1199) {
    margin-bottom: 70px;
  }
}

.happy-customer {
  margin-bottom: 2.15rem;
  font-weight: 400;
  font-size: 24px;
  @include max-screen(767) {
    font-size: 20px;
  }
}

.testimonial-text {
  font-size: 18px;
}
.quote {
  color: #151515;
  font-size: 50px;
  transform: rotate(180deg);
}

.avater-profile {
  margin-right: 1.25rem;
  img {
    border: 50%;
  }
}
.avater-info {
  p {
    font-family: "Metropolis Semi Bold";
    color: #636363;
    margin-bottom: 0;
    line-height: 1;
    font-size: 18px;
  }
  span {
    font-family: "Metropolis Light";
    color: #636363;
    font-weight: 300;
    font-size: 15px;
  }
}

// testimonial-carousel navigation style

.testimonial-carousel {
  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    margin-top: 0;
    bottom: -80px;
    color: $primary;
    text-transform: capitalize;
    text-decoration: underline;
  }
  .swiper-button-prev {
    width: auto;
    left: 0;
  }

  .swiper-button-next {
    width: 80px;
    left: 60px;
  }
}

.testimonial-carousel {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}

.tetimonial-shape {
  
  position: absolute;
  top: 5%;
  right: 0;
  
  @include min-screen(992) {
    top: 50%;
    transform: translateY(-50%);

  }
  z-index: -1;
}
