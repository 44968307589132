/*--------------------------------------
/*  Brand Css Start
----------------------------------------*/

.single-brand {
  text-align: center;
  .thumb {
    display: block;
  }
}

.swiper-button-next{
  color:#59E3A7;
}


.swiper-button-prev{
  color:#59E3A7;
}

.brand-heading{
  text-align: center;
  margin-bottom: 60px;
}

.swiper-width {
  transform: scale(0.7);
}

.spacer{
  margin-top: 11%;
}


.branded-image{
  width: 158px;
height: 58px;

}

.image-gray{
  filter: grayscale(100%);
}
/*--------------------------------------
/*  Brand Css ENd
----------------------------------------*/
